import { Home } from "./routes/Home";
import { Catalog } from "./routes/Catalog";
import { FunctionComponent } from "react";
import { List } from "./routes/List";
import { DepartmentList } from "./routes/DepartmentList";
import { Department } from "./routes/Department";
import { DadosAbertosList } from "./routes/OpenDataList";
import { OpenData } from "./routes/OpenData";

export const Routes: Record<string, FunctionComponent<any>> = {
  "/orgao/:orgao": Department,
  "/orgao": DepartmentList,
  "/dados/:resources/:category/:query": OpenData,
  "/dados/:resources/:category": OpenData,
  "/dados/:resources": OpenData,
  "/dados": DadosAbertosList,
  "/search/:query?": List,
  "/list/orgao/:orgao": List,
  "/list/:category/:query": List,
  "/list/:category": List,
  "/list": List,
  "/:category/:catalog": Catalog,
  "/": Home,
};
