import React, { FunctionComponent, useContext } from "react";
import "./header.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import { FloatingHeader } from "./floating-header";
import { Link } from "react-scroll";
import { CategoryBannerContext } from "../../providers/category-banner-provider";

export interface HeaderProps {
  showFloatingHeader: boolean;
  SearchComponent: FunctionComponent<any>;
  LoginComponent: FunctionComponent<any>;
}
export const Header = ({
  SearchComponent,
  showFloatingHeader,
  LoginComponent,
}: HeaderProps) => {
  const mainClassName = classNames({
    "xvia-catalog-header": true,
    "xvia-catalog-header--fixed": showFloatingHeader,
    "xvia-catalog-header--visible": !showFloatingHeader,
  });
  const { isOpen, onClickHeader } = useContext(CategoryBannerContext);
  return (
    <div className={mainClassName}>
      <FloatingHeader RightComponent={SearchComponent} />
      <div className="xvia-catalog-header__image-wrapper">
        <img
          height={48}
          src={`${process.env.PUBLIC_URL}/images/svgs/logo_white.svg`}
          className="xvia-catalog-header__image"
          alt="Governo do Estado do Piauí"
        />
      </div>
      <div className="xvia-catalog-header__links">
        <ul>
          <li>
            <a href="/app/catalog/list/servicos-ao-cidadao">Cidadão</a>
          </li>
          <li>
            <a href="/app/catalog/list/servidor-publico">Servidor</a>
          </li>
          <li>
            <Link
              activeClass="active"
              to="category"
              spy={true}
              smooth={true}
              duration={500}
              offset={-120}
              onClick={() => isOpen || onClickHeader()}
            >
              Serviços
            </Link>
          </li>
        </ul>
      </div>
      <div className="xvia-catalog-header__elements">
        <LoginComponent />
      </div>
    </div>
  );
};
